<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <p class="pbold fontcolor-mainblue" name="header">
            {{modaltitle}}
          </p>
          <button
            type="button"
            @click="close"
          >
            <img src="../assets/icons/close.svg">
          </button>
        </header>

        <section class="modal-body">
          <p class="pregular fontcolor-mainblue" name="body">
            {{modaltext}}
          </p>
        </section>
      </div>
    </div>
  </transition>
</template>


<!--____________________________________________________________________________________________-->

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(11, 16, 16, 0.7);
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
   background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-height: 70%;
    width: 95%;
    margin-top: 20vh;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 10px;
    height: fit-content;

  }

  .modal-header {
    padding: 15px 0px 5px 0px;
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: none;
  }


  .modal-body {
    position: relative;
    padding: 0px 15px 0px 0px;
    text-align: left;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0%;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .2s ease;
  }
</style>


<!--____________________________________________________________________________________________-->

<script>
  export default {
    name: 'Modal',
    props: ['modaltitle', 'modaltext',],
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>