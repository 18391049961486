<template>
  <div>
    <ul class="ultabs">
      <li
        v-for="(tab, index) in tabList"
        :key="index"
        :class="{
          'active shadow-xl': index + 1 === activeTab,
          'text-white': index + 1 !== activeTab,
        }"
      >
        <label
          :for="`${_uid}${index}`"
          v-text="tab"
          class="cursor-pointer block"
        />
        <input
          :id="`${_uid}${index}`"
          type="radio"
          :name="`${_uid}-tab`"
          :value="index + 1"
          v-model="activeTab"
          class="hidden"
        />
      </li>
    </ul>
    <template v-for="(tab, index) in tabList">
      <div
        :key="index"
        v-if="index + 1 === activeTab"
        class="margintop20"
      >
        <slot :name="`tabPanel-${index + 1}`"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      activeTab: 1,
    };
  },
};
</script>

<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  white-space: nowrap;
  margin-left: 10px;
}

li {
  float: left;
  border: solid 1px;
  border-radius: 20px;
  padding: 9px 18px;
  margin-right: 8px;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

label {
    display: inline-block;
    margin-bottom: 0rem;
}

input[type=radio] {
    display: none;
}

text-blue-600 {
  background-color: white;
}

ul.ultabs li {
  background-color: #181E22;
  border: solid 1px #181E22;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.text-white {
  color: #93979A!important;
}
</style>