<template>
  <div>
    <div :class='["feedback", getFeedback()]'>
      <div class="container">
        <div class="row">
          <div class="col-6 center">
            <p class="psemibold fontcolor-white">{{date}}</p>
          </div>
          <div class="col-6 col-right center">
            <!-- Info aus Datenbank -->
            <h2 class="h2 display-inline fontcolor-white">{{value.data}}</h2>
            <h4 class="h4 display-inline fontcolor-white" style="padding-left:8px">{{unit}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .beobachtung {
    background-color: #234BDB;
  }
  .warnung {
    background-color: #003778;
  }
  .good {
    background-color: #4E75FF;
  }
  .feedback {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 2px;
    display: block;
    border: none;
  }
</style>

<script>
export default {
  data() {
      return {
        //feedback: "good",
      }
  },
  props: ['date', 'value', 'unit', 'minNormal', 'minBeobachtung', 'maxNormal', 'maxBeobachtung'],
  methods: {
    getFeedback() {

      console.log(this.value);
      let input = parseFloat(this.value.data);
      console.log(input);
      console.log(parseFloat(this.minNormal));
      console.log(parseFloat(this.maxNormal));

      if(input){
        

        if(input >= parseFloat(this.minNormal) && input <= parseFloat(this.maxNormal)){
          return "good";
        } else if(input > parseFloat(this.minBeobachtung) && input < parseFloat(this.maxBeobachtung)){
          return "beobachtung";
        } else {
          return "warnung";
        }
      } else {
        // bei nicht lesbarem wert:
        return "good";
      }
    }
  }
}
</script>
 No newline at end of file
