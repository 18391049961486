<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2 center" href="">
          </div>
          <div class="col-8 col-center center">
            <p class="pbolduppercase fontcolor-white" >Werteverlauf</p>
          </div>
          <div class="col-2 col-right center">
            <button type="button" @click="showModal">
              <img src="../assets/icons/export_white.svg">
            </button>

            <Modal
              v-show="isModalVisible"
              @close="closeModal"
              modaltitle="Werteexport"
              modaltext="Hier können Sie sich zukünftig Ihre eingespeicherten Werte als PDF Datei exportieren."
            />
          </div>
        </div>
      </div>
    </div>

    <div class="margintop140 paddingbottom110">
    
      <werteverlauf-tabs class="scrollmenu" :tabList="tabList">
        <template v-slot:tabPanel-1 class="margintop20">
          <div>
            <!-- {{ getVerlauf("temperatur")}} -->
            <werteverlauf-listelement v-for='d in getVerlauf("temperatur")' :key='d.id' :date="d.tag" :value="d.wert" unit="°C" minNormal="36.5" maxNormal="37.5" minBeobachtung="36" maxBeobachtung="38"></werteverlauf-listelement>
          </div>
        </template>
        <template v-slot:tabPanel-2>
          <div>
             <werteverlauf-listelement v-for='d in getVerlauf("blutdruck")' :key='d.id' :date="d.tag" :value="d.wert" unit="mmHg" minNormal="95" maxNormal="130" minBeobachtung="90" maxBeobachtung="165"></werteverlauf-listelement>
          </div>
        </template>
        <template v-slot:tabPanel-3>
          <div>
            <werteverlauf-listelement v-for='d in getVerlauf("gewicht")' :key='d.id' :date="d.tag" :value="d.wert" unit="kg"  :minNormal="parseFloat(getGewicht) - 0.74" :maxNormal="parseFloat(getGewicht) + 0.74" :minBeobachtung="parseFloat(getGewicht) - 0.75" :maxBeobachtung="parseFloat(getGewicht) - 0.75"></werteverlauf-listelement>
          </div>
        </template>
      </werteverlauf-tabs>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
import FootNavigationVue from "../components/FootNavigation.vue";
import WerteverlaufListelementVue from '../components/WerteverlaufListelement.vue';
import WerteverlaufTabs from "../components/WerteverlaufTabs.vue";
import Modal from "../components/Modal.vue";
import { mapGetters } from "vuex";

export default {
  name: 'Werteverlauf',
  components: {
    'foot-navigation' :FootNavigationVue,
    'werteverlauf-listelement' : WerteverlaufListelementVue,
    'werteverlauf-tabs': WerteverlaufTabs,
    Modal,
  },
  data() {
    return {
      tabList: ["Temperatur", "Blutdruck", "Gewicht"],
      isModalVisible: false,
    };
  },
  methods: {
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    },
    computed: {
      ...mapGetters(["getVerlauf", "getGewicht"]),
    }
};
</script>

<style>

.active {
  background-color: #25323A!important;
  font-weight: 550;
  color: white;
}

.scrollmenu {
  
}
</style>